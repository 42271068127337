import React from 'react';

import {
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBRow,
} from "mdb-react-ui-kit";

import './aboutUs.css'
import Search from "../../search/search";
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";

const AboutUs = () => {
    const location = useLocation();
    const canonicalUrl = `https://vintrust.pro${location.pathname}`;
    return (
        <div className='about-us-container'>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div className='about-intro'>
                <div className='intro-section-image'>
                    <div className='intro-image-container'>
                        <img className='about-us-intro-image' src="/img/about-us2.png" alt="Vintrust team, values and mission"/>
                    </div>
                </div>
                <div className='intro-section'>
                    <div className='about-us-title-container'>
                        <h1 className='about-us-title-text'>About VinTrust</h1>
                    </div>
                    <h2 className='intro-description-italic'>We're dedicated to serving the car community.</h2>
                    <p className='intro-description'>Since 2020, our team has been dedicated to creating and providing
                        top-notch data products. We
                        take pride in the trust millions of customers place in us, returning time and again for
                        dependable solutions to their inquiries.</p>
                    <p className='intro-description'>We specialize in providing comprehensive vehicle history reports to
                        empower car buyers, sellers,
                        and
                        enthusiasts alike.</p>
                </div>
            </div>
            <div className='testimonials'>
                <MDBContainer className="py-5">
                    <MDBRow className="d-flex justify-content-center">
                        <MDBCol md="10" xl="8" className="text-center">
                            <h3 className="mb-4 testimonials-big-text">Testimonials</h3>
                            <p className="mb-4 pb-2 mb-md-5 pb-md-0 testimonials-medium-text">
                                Uncover the hidden stories within vehicle histories, empowering confident purchases and
                                informed decisions. Discover what our satisfied customers have to say about our service.
                            </p>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="text-center d-flex align-items-stretch">
                        <MDBCol md="4" className="mb-5 mb-md-0 d-flex align-items-stretch">
                            <MDBCard className="testimonial-card">
                                <div
                                    className="card-up"
                                    style={{backgroundColor: "#9d789b"}}
                                ></div>
                                <div className="avatar mx-auto bg-white">
                                    <img alt="Customer of Vintrust"
                                        src="/img/testimonial3.png"
                                        className="rounded-circle img-fluid"
                                    />
                                </div>
                                <MDBCardBody>
                                    <h4 className="mb-4 testimonials-medium-text">Henry Moore</h4>
                                    <hr/>
                                    <p className="dark-grey-text mt-4 testimonials-medium-text">
                                        <MDBIcon fas icon="quote-left" className="pe-2"/>
                                        Clear insights, smooth transactions, indispensable resource.
                                    </p>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="4" className="mb-5 mb-md-0 d-flex align-items-stretch">
                            <MDBCard className="testimonial-card">
                                <div
                                    className="card-up"
                                    style={{backgroundColor: "#7a81a8"}}
                                ></div>
                                <div className="avatar mx-auto bg-white">
                                    <img alt="Customer of Vintrust"
                                        src="/img/testimonial2.png"
                                        className="rounded-circle img-fluid"
                                    />
                                </div>
                                <MDBCardBody>
                                    <h4 className="mb-4 testimonials-medium-text">Jessica Lewis</h4>
                                    <hr/>
                                    <p className="dark-grey-text mt-4 testimonials-medium-text">
                                        <MDBIcon fas icon="quote-left" className="pe-2"/>
                                        Transparent process, reliable information, satisfied customer.
                                    </p>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="4" className="mb-5 mb-md-0 d-flex align-items-stretch">
                            <MDBCard className="testimonial-card">
                                <div
                                    className="card-up"
                                    style={{backgroundColor: "#6d5b98"}}
                                ></div>
                                <div className="avatar mx-auto bg-white">
                                    <img alt="Customer of Vintrust"
                                        src="/img/testimonial1.png"
                                        className="rounded-circle img-fluid"
                                    />
                                </div>
                                <MDBCardBody>
                                    <h4 className="mb-4 testimonials-medium-text">Daniel Brown</h4>
                                    <hr/>
                                    <p className="dark-grey-text mt-4 testimonials-medium-text">
                                        <MDBIcon fas icon="quote-left" className="pe-2"/>
                                        Full transparency, hassle-free process, made confident choice.
                                    </p>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
            <Search
                showTitle={true}
                snowDescription={true}
                titleText='Become another success story'
                descriptionText="Discover the truth about your vehicle's past."
            ></Search>
        </div>
    );
};

export default AboutUs;